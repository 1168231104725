import React, { useState, useEffect } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay } from "swiper"
import SiteImage from "./SiteImage"
import SmartLink from "./SmartLink"
import "./HeroSlides.scss"
import FormattedText from "./FormattedText"

export default function HeroSlides({
  heroSlides,
  thumbnailNames = [],
  thumbnailImages = [],
}) {
  const [swiper, setSwiper] = useState(null)
  const [currentSlide, setCurrentSlide] = useState(0)

  useEffect(() => {
    const swiperInstance = swiper
    const updateIndex = () => setCurrentSlide(swiper.realIndex)
    if (swiperInstance) {
      swiperInstance.on("slideChange", updateIndex)
    }

    return () => {
      if (swiperInstance) {
        swiperInstance.off("slideChange", updateIndex)
      }
    }
  }, [swiper])

  return (
    <section id="hero" className="hero is-medium">
      <div className="hero-body">
        <div className="container">
          <Swiper
            onSwiper={setSwiper}
            a11y={false}
            shortSwipes={false}
            spaceBetween={50}
            modules={[Autoplay]}
            autoplay={{
              delay: 5000,
              disableOnInteraction: true,
            }}
            style={{
              padding: 0,
            }}
          >
            {heroSlides.map((hero, i) => {
              const Title = i === 0 ? "h1" : "h2"
              return (
                <SwiperSlide key={i}>
                  {hero.background && (
                    <SiteImage
                      image={hero.background}
                      className="hero-background"
                      alt=""
                    />
                  )}
                  <div>
                    <div
                      className="columns is-desktop"
                      style={{ boxSizing: "border-box" }}
                    >
                      <div className="column is-7-desktop hero-content">
                        <Title className="mb-5">
                          <div className="title is-2 has-text-silver-gradient">
                            <FormattedText>{hero.title}</FormattedText>
                          </div>
                        </Title>
                        <p>
                          <FormattedText>{hero.description}</FormattedText>
                        </p>
                        <div className="columns is-mobile is-multiline mt-6 mb-4 buttons-container">
                          <div className="column is-12-mobile is-narrow-tablet is-flex is-flex-direction-column is-justify-content-center is-align-items-stretch">
                            <SmartLink
                              to={hero.link}
                              className="button is-gradient is-large is-family-secondary px-6"
                            >
                              {hero.linkTitle}
                            </SmartLink>
                          </div>
                          {hero.secondaryLink && (
                            <div className="column is-12-mobile is-narrow-tablet is-flex is-flex-direction-column is-justify-content-center is-align-items-stretch">
                              <SmartLink
                                to={hero.secondaryLink}
                                className="button is-gradient is-outlined is-large is-family-secondary px-6"
                              >
                                {hero.secondaryLinkTitle}
                              </SmartLink>
                            </div>
                          )}
                        </div>
                      </div>
                      {hero.heroVideo ? (
                        <div className="column is-5-desktop">
                          <div className="iframe-container">
                            <iframe
                              src={hero.heroVideo}
                              loading="lazy"
                              title="YouTube video player"
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              referrerPolicy="strict-origin-when-cross-origin"
                              allowFullScreen
                            ></iframe>
                          </div>
                        </div>
                      ) : (
                        <div className="column is-5-desktop is-flex is-justify-content-center">
                          <SiteImage
                            image={hero.heroImage}
                            objectFit={"contain"}
                            className="hero-image"
                            loading={i === 0 ? "eager" : "lazy"}
                            width="100%"
                            height="100%"
                            alt=""
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
          {/* Thumbnails */}
          <div className="mt-4 thumbnails-container">
            <div className="is-flex is-justify-content-center">
              {heroSlides.map((hero, i) => (
                <div
                  className={`mx-3 p-3 is-flex is-flex-direction-column is-align-items-center thumbnail ${
                    currentSlide === i ? "active" : ""
                  }`}
                  key={hero.id}
                  onClick={() => swiper?.slideTo(i)}
                >
                  <div className="is-hidden-mobile image is-128x128">
                    <SiteImage
                      image={thumbnailImages[i]}
                      width="128px"
                      height="128px"
                      alt=""
                    />
                  </div>
                  <div className="is-hidden-tablet image is-64x64">
                    <SiteImage
                      image={thumbnailImages[i]}
                      width="64px"
                      height="64px"
                      loading="eager"
                      alt=""
                    />
                  </div>
                  <div className="is-size-7 has-text-centered">
                    {thumbnailNames[i] || ""}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
