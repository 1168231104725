import React from "react"
import SectionTitle from "./SectionTitle"
import SiteImage from "./SiteImage"
import SmartLink from "./SmartLink"
import "./AppReviews.scss"

const StarIcon = () => {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8723 1.58494C11.2076 0.553068 12.6674 0.553069 13.0027 1.58494L14.8292 7.2065C14.9792 7.66797 15.4092 7.9804 15.8944 7.9804H21.8053C22.8903 7.9804 23.3414 9.36877 22.4636 10.0065L17.6816 13.4808C17.2891 13.766 17.1248 14.2716 17.2748 14.733L19.1013 20.3546C19.4366 21.3865 18.2556 22.2445 17.3778 21.6068L12.5958 18.1325C12.2033 17.8473 11.6717 17.8473 11.2792 18.1325L6.49719 21.6068C5.61943 22.2445 4.43842 21.3864 4.77369 20.3546L6.60025 14.733C6.75019 14.2716 6.58593 13.766 6.19338 13.4808L1.41139 10.0065C0.533634 9.36877 0.984743 7.9804 2.06971 7.9804H7.98058C8.46579 7.9804 8.89582 7.66797 9.04576 7.2065L10.8723 1.58494Z"
        fill="#FFC107"
      />
    </svg>
  )
}

const AppReviews = ({
  sectionTitle,
  title,
  description,
  reviews,
  appLinks,
}) => {
  return (
    <section id="app-reviews" className="section">
      <div className="container p-4 is-flex is-flex-direction-column is-align-items-center">
        <SectionTitle
          title={title}
          description={description}
          announceText={sectionTitle}
        />
        <div className="is-flex my-4">
          {appLinks.storeLinks.map(link => (
            <SmartLink key={link.id} to={link.link} className="mr-4">
              <SiteImage image={link.image} alt={link.title} />
            </SmartLink>
          ))}
        </div>
        <div className="columns is-multiline is-centered review-card-container">
          {reviews.map(review => (
            <div
              className="column is-6-tablet is-12-mobile px-4 py-4"
              key={review.id}
            >
              <div className="review-card">
                <div className="review-header">
                  <div>
                    <div className="review-rating">
                      {[...Array(review.rating)].map((_, index) => (
                        <StarIcon key={index} />
                      ))}
                    </div>
                    <h4 className="review-title">{review.title}</h4>
                  </div>
                  {review.image && (
                    <div className="review-avatar">
                      <SiteImage image={review.image} alt="" />
                    </div>
                  )}
                </div>
                <div className="review-meta">
                  <p className="review-description">{review.description}</p>
                  <div className="review-username">{review.username}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default AppReviews
