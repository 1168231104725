import React from "react"
import SectionTitle from "./SectionTitle"
import "./VideoSection.scss"

export default function VideoSection({
  announceText,
  title,
  description,
  videoLinks,
  buttonLink,
}) {
  return (
    <section id="tournament-video-section" className="section">
      <div className="container p-5">
        <SectionTitle
          announceText={announceText}
          title={title}
          description={description}
        />
        <div className="columns mt-6 is-multiline is-align-self-stretch">
          {videoLinks.map(({ id, link }) => {
            return (
              <div
                className="column is-half is-flex is-flex-direction-column is-align-items-stretch"
                key={id}
              >
                <div className="iframe-container">
                  <iframe
                    src={link}
                    loading="lazy"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            )
          })}
        </div>
        <div className="is-flex is-justify-content-center">
          <a
            href={buttonLink.link}
            target="_blank"
            rel="noreferrer"
            className="m-5 button is-gradient is-family-secondary has-text-white is-rounded"
          >
            {buttonLink.title}{" "}
          </a>
        </div>
      </div>
    </section>
  )
}