import React, { useState } from "react"
import SectionTitle from "./SectionTitle"
import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"
import "./FaqSection.scss"

function FaqItem({ question, answer }) {
  const [isActive, setIsActive] = useState(false)

  const plusIcon = (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="32"
        height="32"
        rx="16"
        fill="url(#paint0_radial_10642_32618)"
      />
      <rect
        width="32"
        height="32"
        rx="16"
        fill="url(#paint1_radial_10642_32618)"
      />
      <path
        d="M16 24V16M16 16V8M16 16H8M16 16H24"
        stroke="url(#paint2_radial_10642_32618)"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <defs>
        <radialGradient
          id="paint0_radial_10642_32618"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.0947 16) rotate(90) scale(12.5176 12.595)"
        >
          <stop stopColor="#25224F" stopOpacity="0.64" />
          <stop offset="1" stopColor="#1C1942" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_10642_32618"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.0947 16) rotate(90) scale(42.6353 42.8473)"
        >
          <stop stopColor="#D7C2FF" stopOpacity="0" />
          <stop offset="1" stopColor="#3A376B" stopOpacity="0.1" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_10642_32618"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16 16) rotate(90) scale(8)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.46" />
        </radialGradient>
      </defs>
    </svg>
  )

  const minusIcon = (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="#13002E" />
      <path
        d="M23 16H16H9"
        stroke="#ECECEC"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )

  return (
    <div className={`item-container p-3 mb-3 ${isActive ? "active" : ""}`}>
      <div className="background"></div>
      <div
        className="item-question has-text-weight-semibold is-flex is-justify-content-space-between is-align-items-center"
        onClick={() => setIsActive(p => !p)}
      >
        <h3>{question}</h3>
        {isActive ? minusIcon : plusIcon}
      </div>
      {isActive && (
        <div className="mt-5">
          <ReactMarkdown remarkPlugins={[remarkGfm]} transformLinkUri={null}>
            {answer}
          </ReactMarkdown>
        </div>
      )}
    </div>
  )
}

export default function FaqSection({ announceText, title, faqItems }) {
  return (
    <section id="faq" className="section">
      <div className="container">
        <SectionTitle
          announceText={announceText}
          title={title}
          description=""
        />
        <div>
          {faqItems.map(({ question, answer, id }) => (
            <FaqItem question={question} answer={answer.data.answer} key={id} />
          ))}
        </div>
      </div>
    </section>
  )
}